<template>
  <v-dialog
    persistent
    v-model="dialogCreateDisputeActive"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.createdispute.textTitle }}</p>
      <!-- start: upload 1 -->
      <v-btn
        @click="$refs.inputUpload.click()"
        v-if="dataImgs.length == 0"
        class="button-add-document mon-bold mt-5"
        elevation="0"
      >
        <div>
          <v-icon
            color="#B2B2B2"
            size="60px"
          >
            mdi-image-plus
          </v-icon>
          <p
            class="text-button-document mon-semibold mt-2"
            v-html="texts.createdispute.textLabelImage"
          />
        </div>
        <input
          ref="inputUpload"
          style="display: none;"
          type="file"
          size="60"
          accept=".jpg, .jpeg, .png"
          multiple="true"
          @change="uploadFilesWarehouse"
        >
      </v-btn>
      <div
        v-else
        id="status-input-zone"
        class="mt-5"
      >
        <div
          v-for="(item, index) in dataImgs"
          :key="index"
          class="content-image-articles"
        >
          <v-btn
            @click="removeItem(item, index)"
            icon
            class="button-delete-item"
          >
            <v-icon
              size="25px"
              color="#D8686A"
            >
              mdi-delete
            </v-icon>
          </v-btn>
          <img
            :src="maskedFile(item)"
            alt=""
          >
        </div>
      </div>
      <!-- end: upload 1 -->
      <div class="mt-5">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-select
                v-model="disputeCategory"
                :label="texts.createdispute.textDisputeCategory"
                :placeholder="texts.createdispute.textDisputeCategory"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aCategories"
                item-text="sName"
                item-value="sClaimTicketCategoryId"
                @change="selectSubcategory"
              ></v-select>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-select
                :disabled="activeSubcategory"
                v-model="disputeSubcategory"
                :label="texts.createdispute.textDisputeSubcategory"
                :placeholder="texts.createdispute.textDisputeSubcategory"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :items="aSubcategories"
                item-text="sName"
                item-value="sClaimTicketSubcategoryId"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="mt-4">
        <v-textarea
          v-model="disputeComments"
          class="global-inputs mon-regular"
          :label="texts.createdispute.textComments"
          :placeholder="texts.createdispute.textComments"
          outlined
          color="#000000"
          dense
          rows="3"
          maxLength="250"
        ></v-textarea>
      </div>
      <div class="display-flex align-items-center justify-content-flex-end mt-7">
        <v-btn
          @click="closeDialog"
          elevation="0"
          class="button-cancel mon-regular"
        >
          {{ texts.createdispute.buttonCancel }}
        </v-btn>
        <v-btn
          @click="confirmDispute"
          :disabled="!validateForm"
          :loading="bLoading"
          elevation="0"
          class="button-confirm mon-bold ml-2"
        >
          {{ texts.createdispute.buttonConfirm }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateDisputeComponent",
  data() {
    return {
      disputeCategory: "",
      disputeSubcategory: "",
      disputeComments: "",
      texts: "",
      screenWidth: 0,
      bLoading: false,
      dataImgs: [],
      aCategories: [],
      aSubcategories: [],
      activeSubcategory: true
    };
  },
  beforeMount() {
    this.texts = FILE.createdispute[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;

      if (files.length > 0) {
        if (files.length < 16) {
          for (let i = 0; i < files.length; i++) {
            const element = files[i];
            this.dataImgs.push(element);
          }
        } else {
          this.mixError(this.texts.createdispute.textResponseLimitImages);
        }
      }
    },
    confirmDispute: function () {
      this.bLoading = true;

      const payload = {
        sDescription: this.disputeComments,
        sSalesOrderId: this.dialogCreateDisputeArr.sSalesOrderId,
        sClaimTicketSubcategoryId: this.disputeSubcategory,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets`,
        payload,
        config
      )
        .then((response) => {
          if (this.dataImgs.length > 0) {
            this.uploadImgs(
              response.data.message,
              response.data.results.sClaimTicketId
            );
          } else {
            this.bLoading = false;

            this.closeDialog();
            this.mixSuccess(response.data.message);
            this.$router.push("/disputes/tab/1");
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadImgs: function (responseMsg, disputeId) {
      let form = new FormData();

      this.dataImgs.forEach((element) => {
        form.append("file", element);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets/${disputeId}/images`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.closeDialog();
          this.mixSuccess(responseMsg);
          this.$store.commit("refresher", true);
          this.$router.push("/disputes/tab/1");
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogCreateDispute", {
        active: false,
        arr: [],
      });

      this.disputeCategory = "";
      this.disputeSubcategory = "";
      this.disputeComments = "";
      this.dataImgs = [];
      this.aCategories = [];
      this.aSubcategories = [];
      this.activeSubcategory = true;
    },
    removeItem: function (item, index) {
      this.dataImgs.splice(index, 1);
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file);
    },
    getDisputeCategories: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/claim-tickets/categories`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aCategories = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectSubcategory: function () {
      this.activeSubcategory = false;
      this.getDisputeSubcategories();
    },
    getDisputeSubcategories: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets/categories/${this.disputeCategory}/subcategories`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.aSubcategories = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    // return create contact dialog state
    dialogCreateDisputeActive: function () {
      return this.$store.state.dialogCreateDisputeActive;
    },
    dialogCreateDisputeArr: function () {
      return this.$store.state.dialogCreateDisputeArr;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.disputeCategory !== "" &&
        this.disputeCategory !== null &&
        this.disputeSubcategory !== "" &&
        this.disputeSubcategory !== null &&
        this.disputeComments !== ""
      );
    },
  },
  watch: {
    dialogCreateDisputeActive: function () {
      if (this.dialogCreateDisputeActive) {
        this.getDisputeCategories();
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.createdispute[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.button-cancel {
  height: 40px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-confirm {
  height: 40px !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.close-dialog {
  position: absolute;
  right: 10px;
  top: 10px;
}

.text-title {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.button-delete:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

#status-input-zone {
  width: 100% !important;
  height: 275px !important;
  overflow: auto;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707070;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button-delete-item {
  position: absolute;
  right: -1px;
  top: -1px;
  min-width: 40px !important;
  height: 40px !important;
  background-color: #ffffff99 !important;
  padding: 0px !important;
  border-radius: 10px;
}

.text-button-document {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 1;
  text-transform: initial;
}

.button-add-document {
  width: 100% !important;
  height: 160px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707080;
  border-radius: 20px;
  opacity: 1;
}

.content-image-articles {
  width: 120px;
  height: 120px;
  margin: 5px;
  position: relative;
}

.content-image-articles img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-articles img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}
</style>